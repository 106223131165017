.modal {
  display   : block;
  overflow  : auto;
}

.fade.modal.modal-hoc-enter-done {
  opacity: 1;
}

.fade.modal-backdrop.modal-hoc-backdrop-enter-done {
  opacity: .5;
}

.modal.modal-hoc-enter-done .modal-dialog {
  -webkit-transform: none;
  transform: none;
}